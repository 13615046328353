<template>
  <a-input :value="props.name" :size="props.size" placeholder="请选择部门" readonly @click="open"  :disabled="disabled">
    <template #suffix>
      <i class="fa fa-sitemap gray"/>
    </template>
  </a-input>
  <a-modal title="选择部门" :visible="state.show" :confirm-loading="state.loading" width="600px" class="z-input-dept"
           @ok="save" @cancel="state.show = false">
    <div class="ui-toolbar">
      <input type="text" v-model="state.keyword" class="keyword" placeholder="搜索名称"/>
      <a-button type="primary" @click="load()">搜索</a-button>
      <a-button type="primary" @click="reset()" danger>重置</a-button>
    </div>
    <a-tree :tree-data="data.tree" v-model:selectedKeys="state.selectedKeys" v-model:checkedKeys="state.checkedKeys"
            v-model:expanded-keys="state.expandedKeys"
            :checkable="multiple"
            @select="nodeSelected"/>
  </a-modal>
</template>

<script lang="ts">
export default {
  name: 'z-input-dept'
}
</script>

<script lang="ts" setup>
import {reactive} from "vue"
import {t} from '../config/tools'

const $emit = defineEmits(['selected', 'update:id', 'update:name', /* rules自动生成事件 */ 'blur', 'change'])
const props = defineProps({
  id: String,
  multiple: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'small'
  },
  name: {
    type: String
  },
  level: {
    type: Number,
    default: 1
  },
  checkUserDept: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  options: {
    type: Object,
    default: {}
  },
})

/// region 初始化值
const state = reactive({
  show: false,
  loading: false,
  keyword: '',
  checkedKeys: [] as string[],
  selectedKeys: [] as string[],
  selectedNode: null,
  selectedNodes: [] as any[],
  expandedKeys: [] as any[]
})
const data = reactive({
  init: false,
  tree: [],
  sourceData: [] as any[],
})
/// endregion

/// region 方法
const open = () => {
  state.show = true
  load()
}
const load = () => {
  state.loading = true
  let args = {
    deptName: state.keyword
  }
  const url = props.checkUserDept ? '/ams/system/query-dept-tree' : '/ams/system/query-dept-tree-all'
  t.get(url, args).then((d: any) => {
    data.sourceData = d.data
    // 遍历建立树结构
    const build = (node: any) => {
      let children = d.data.filter((d: any) => d.pid === node.key).map((item: any) => {
        return {
          key: item.id,
          title: item.name
        }
      })
      if (children.length > 0) {
        node.children = children
        children.map((dept: any) => {
          build(dept)
        })
      }
    }

    // 获取根节点
    let root = d.data.filter((e: any) => d.data.filter((ee: any) => e.pid === ee.id).length === 0).map((item: any) => {
      return {
        key: item.id,
        title: item.name
      }
    })
    root.forEach((item: any) => {
      build(item)
    })
    data.tree = root
    // 设置展示的层级
    if (!data.init && props.level > 1) {
      const expandedNodes = (node: any, level: number) => {
        if (level < props.level) {
          state.expandedKeys.push(node.key)
          if (node.children) {
            node.children.forEach((n: any) => {
              expandedNodes(n, level + 1)
            })
          }
        }
      }
      root.forEach((n: any) => {
        expandedNodes(n, 1)
      })
    }
    data.init = true
    // 设置默认值
    if (props.id) {
      if (props.multiple) {
        // 多选模式
        state.checkedKeys = props.id.split(',')
      } else {
        // 单选模式
        state.selectedKeys = [props.id]
      }
    }
    state.loading = false
  }).catch((err: any) => {
    state.loading = false
  })
}
/**
 * 重置
 */
const reset = () => {
  state.keyword = ''
  load()
}
/**
 * 节点选中事件
 */
const nodeSelected = () => {
  // 单选数据
  if (!props.multiple) {
    let d = data.sourceData.find((d: any) => state.selectedKeys.indexOf(d.id) > -1)
    $emit('update:id', d.id)
    $emit('update:name', d.name)
    $emit('selected', d)
    state.show = false
  }
}
/**
 * 保存
 */
const save = () => {
  let d = data.sourceData.filter((d: any) => state.checkedKeys.indexOf(d.id) > -1) as any
  $emit('update:id', d.map((d: any) => d.id).join(','))
  $emit('update:name', d.map((d: any) => d.name).join(','))
  $emit('selected', d)
  state.show = false
}
/// endregion

defineExpose({
  open
})
</script>

<style lang="less">
</style>
