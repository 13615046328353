<template>
  <div class="main-page">
    <a-config-provider :locale="zhCN" componentSize="small">
      <ui-header/>
      <div class="ui-body">
        <ui-sidebar/>
        <!--
        <div class="ui-local-sidebar">
          <router-link to="/">首页</router-link>
          <a-divider/>
          <router-link to="asset-add">资产登记</router-link>
          <router-link to="asset-info">资产信息</router-link>
          <a-divider/>
          <router-link to="asset-query-list">资产查询</router-link>
          <router-link to="check-plan-list">资产盘点</router-link>
          <a-divider/>
          <router-link to="borrow-record">资产借用</router-link>
          <router-link to="damage-or-lose">资产损坏/丢失</router-link>
          <router-link to="depreciation">资产折旧</router-link>
          <router-link to="maintain-record">资产维修记录</router-link>
          <router-link to="move">资产转移</router-link>
          <router-link to="scrap">资产报废</router-link>
          <a-divider/>
          <router-link to="asset-category">资产分类</router-link>
          <router-link to="supplier">经销商管理</router-link>
          <router-link to="operation-log-list">操作日志</router-link>
          <router-link to="dict-list">字典管理</router-link>
          <router-link to="dict-sys-list">系统字典管理</router-link>
          <router-link to="attachment-list">附件管理</router-link>
        </div>
      -->

        <div class="ui-content">
          <router-view></router-view>
        </div>

      </div>
    </a-config-provider>
  </div>
</template>

<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import {onMounted} from "vue";
import {t} from "./config/tools";
import router from "./router";

const init = () => {
  const size = document.getElementsByClassName("cc-info-item").length
  for (let i = 0; i < size; i++) {
    document.getElementsByClassName("cc-info-item")[i].addEventListener("click", function (e: any) {
      if (e.target.innerText === '退出') {
        sessionStorage.clear()
        localStorage.clear()
        window.location.reload()
      }
    })
  }
}

const initUrl = () => {
  const query: any = t.getQsParams(window.location.href)
  console.log("参数:", query)
  if (query['url']) {
    router.push( { path: query['url'] } )
  }
}

onMounted(() => {
  init()
  initUrl()
})
</script>

<style lang="less">
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }

  .ant-modal-body {
    flex: 1;
  }
}
</style>
