<template>
  <a-input :value="name" :size="size" placeholder="请选择用户" readonly @click="open"  :disabled="disabled">
    <template #suffix>
      <i class="fa fa-user gray"/>
    </template>
  </a-input>
  <a-modal title="选择用户" :visible="state.show" :confirm-loading="state.loading" width="1000px" class="z-input-user"
           @ok="save" @cancel="state.show = false">
    <a-layout>

      <a-layout-sider width="320" style="background: #fff" size="small">
        <a-tree v-model:expandedKeys="tree.expandedKeys" v-model:selectedKeys="tree.selectedKeys" :tree-data="tree.data" @select="nodeSelected" :size="size"/>
      </a-layout-sider>

      <a-layout-content :style="{ background: '#fff', minHeight: '280px' }">
        <div class="ui-toolbar">
          <input type="text" v-model="state.keyword" class="keyword" placeholder="搜索名称"/>
          <a-button type="primary" @click="loadUsers()">搜索</a-button>
          <a-button type="primary" @click="reset()" danger>重置</a-button>
        </div>
        <a-table size="small" class="table-users"
                 :columns="users.columns" :data-source="users.data" row-key="id"
                 :row-selection="multiple ? { selectedRowKeys: users.selectedRowKeys, onChange: onTableRowChanged } : null"
                 :pagination="users.pagination"
                 :loading="state.loading"
                 :custom-row="onTableRowEvent"
                 @change="tableChanged">
        </a-table>
      </a-layout-content>

    </a-layout>
  </a-modal>
</template>

<script lang="ts">
export default {
  name: 'z-input-user'
}</script>

<script lang="ts" setup>
import {reactive} from "vue"
import {t} from '../config/tools'

const $emit = defineEmits(['selected', 'update:id', 'update:name', /* rules自动生成事件 */ 'blur', 'change'])
const props = defineProps({
  id: String,
  multiple: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'small'
  },
  name: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const state = reactive({
  show: false,
  deptData: [] as any[],
  currentDeptId: '',
  loading: false,
  keyword: null as any
})
const tree = reactive({
  expandedKeys: [],
  selectedKeys: [],
  data: []
})
const users = reactive({
  columns: [
    {title: '名称', dataIndex: 'name', width: '30%'},
    {title: '电话', dataIndex: 'phone'}
  ],
  data: [],
  pagination: {
    total: 200,
    current: 1,
    pageSize: 10
  },
  selectedRowKeys: [] as any[],
  selections: [] as any[]
})

const open = () => {
  loadDepts()
  loadUsers()
  state.show = true
}
const loadDepts = () => {
  state.loading = true
  t.get('/ams/system/query-dept-tree', {}).then((d: any) => {
    state.deptData = d.data
    // 遍历建立树结构
    const build = (node: any) => {
      let children = d.data.filter((d: any) => d.pid === node.key).map((item: any) => {
        return {
          key: item.id,
          title: item.name
        }
      })
      if (children.length > 0) {
        node.children = children
        children.map((dept: any) => {
          build(dept)
        })
      }
    }
    // 获取根节点
    let root = d.data.filter((d: any) => d.code.length <= 2).map((item: any) => {
      return {
        key: item.id,
        title: item.name
      }
    })
    root.map((item: any) => {
      build(item)
    })
    tree.data = root
    state.loading = false
  }).catch((err: any) => {
    state.loading = false
  })
  state.show = true
}

const loadUsers = (deptId: string = '') => {
  let args = {
    page: users.pagination.current,
    limit: users.pagination.pageSize,
    deptId: deptId,
    name: state.keyword
  }
  t.get('/ams/system/query-users-by-dept', args).then((d: any) => {
    users.data = d.data

    // 设置默认值
    if (props.id) {
      if (props.multiple) {
        // 多选模式
        users.selectedRowKeys = props.id.split(',')
      } else {
        // 单选模式
        users.selectedRowKeys = [props.id]
      }
    }

  })
}

const nodeSelected = () => {
  if (tree.selectedKeys.length > 0) {
    state.currentDeptId = tree.selectedKeys[0]
    loadUsers(state.currentDeptId)
  }
}
const onTableRowChanged = (selectedRowKeys: any[], selections: any[]) => {
  users.selectedRowKeys = selectedRowKeys
  users.selections = selections
}
const tableChanged = (pagination: any, filters: any, sorter: any) => {
  users.pagination = pagination
  loadUsers(state.currentDeptId)
}
/**
 * 单选模式
 * @param data
 */
const onTableRowEvent = (data: any) => {
  return {
    onClick: (event: any) => {
      if (!props.multiple) {
        $emit('update:id', '' + data.id)
        $emit('update:name', data.name)
        $emit('selected', data)
        state.show = false
      }
    }
  }
}
/**
 * 重置
 */
const reset = () => {
  state.keyword = ''
  loadUsers()
}
/**
 * 多选模式
 */
const save = () => {
  $emit('update:id', users.selections.map((d: any) => d.id).join(','))
  $emit('update:name', users.selections.map((d: any) => d.name).join(','))
  $emit('selected', users.selections)
  state.show = false
}

defineExpose({
  open
})
</script>

<style lang="less">
.z-input-user {
  .table-users {
    position: sticky;
    top: 0;
  }
}
</style>
