<template>
  <a-tree-select v-model:value="props.value" show-search allow-clear placeholder="请选择"
                 style="width: 100%"
                 tree-default-expand-all
                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                 :tree-data="tree.data"
                 @change="dictSelected"
  >
  </a-tree-select>
</template>

<script setup lang="ts">
import {onMounted, reactive, ref} from 'vue'
import {t} from '../config/tools'

/// region 定义输入输出
const props = defineProps({
  size: {
    type: String,
    default: 'small'
  },
  code: {
    type: String,
    require: true
  },
  label: {
    type: String,
    require: true
  },
  lastChoose: {
    type: Boolean,
    require: false,
    default: false
  },
  value: {
    type: String,
    require: true
  }
})
const $emit = defineEmits(['update:label', 'update:value', /* rules自动生成事件 */ 'blur', 'change'])
/// endregion

/// region 初始化变量
const tree = reactive({
  data: [] as any[]
})
/// endregion

onMounted(() => {
  load()
})

const load = async () => {
  const group: any = await t.get('/ams/dict/query-group', {
    code: props.code
  })
  const dicts: any = await t.get('/ams/dict/query-dict-list', {
    group: props.code
  })
  if (group && group.data && dicts) {
    tree.data = buildTree(dicts.data, [], group.data.id, 0)
  }
}

const buildTree = (data: any, tree: any[], pid: string, level: number): any[] => {
  let nodes = data.filter((d: any) => d.pid === pid)
  nodes.forEach((o: any) => {
    let childrenSize = data.filter((d: any) => d.pid === o.id).length
    const selectable = props.lastChoose ? childrenSize === 0 : level >= 0
    let item: any = {
      id: o.id,
      pid: o.pid,
      title: o.dictName,
      value: o.dictCode,
      level: level,
      selectable: selectable,
      children: []
    }
    tree.push(item)
    buildTree(data, item.children, o.id, level + 1)
    if (item.children.length === 0) {
      delete item.children
    }
  })
  return tree
}

const dictSelected = (value: any, label: string) => {
  $emit('update:label', label[0])
  $emit('update:value', value)
}

</script>

<style lang="less">

</style>
