<template>
  <!--表格型弹出框选择器-->
  <a-modal :title="'选择' + title" :visible="show" :confirm-loading="loading" width="1000px" class="cc-dialog-table"
           @ok="save" @cancel="show = false">
    <z-search-bar :columns="columns" v-model:filter="filter" :span="searchBarSpan" @search="load" @reset="resetFilter"></z-search-bar>

    <a-table :columns="columns"
             :data-source="data"
             row-key="id"
             :loading="loading"
             size="small"
             :row-selection="multiple ? { selectedRowKeys: selectedRowKeys, onChange: onTableRowChanged, preserveSelectedRowKeys: preserveSelectedRowKeys } : { preserveSelectedRowKeys: preserveSelectedRowKeys }"
             :pagination="pagination"
             :custom-row="onTableRowEvent"
             @change="tableChanged">
    </a-table>

  </a-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'


  export default defineComponent({
    name: 'cc-dialog-table',
    emits: ['selected', 'loaded', 'update:pagination'],
    props: {
      id: String,
      multiple: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: 'small'
      },
      title: {
        type: String
      },
      preserveSelectedRowKeys: {
        type: Boolean,
        default: false
      },
      url: {
        type: String,
        default: null
      },
      args: {
        type: Object,
        default: {}
      },
      columns: {
        type: Array,
        default: []
      },
      // 搜索条的宽度，按24栅格计算
      searchBarSpan: {
        type: Number,
        default: 8
      }
    },
    data() {
      return {
        show: false,
        loading: false,
        filter: {},
        data: [],
        selectedRowKeys: [] as any[],
        selections: [] as any[],
        pagination: {
          total: 0,
          current: 1,
          pageSize: 7,
          showTotal: (total: any) => {
            return `共 ${total} 条数据`
          }
        }
      }
    },
    methods: {
      resetFilter() {
        if (this.preserveSelectedRowKeys) {
          this.selectedRowKeys = []
          this.selections = []
        }
      },
      open() {
        this.load()
        this.selectedRowKeys = []
        this.selections = []
        this.show = true
      },
      load() {
        this.loading = true
        let params = { page: this.pagination.current, limit: this.pagination.pageSize, ...this.args, ...this.filter }
        this.get(this.url, params).then((d: any) => {
          this.data = d.data
          this.pagination.total = d.count
          this.$emit('loaded', this.data)
          this.loading = false
        }).catch((err: any) => {
          this.loading = false
        })
        this.show = true
      },
      tableChanged(pagination: any, filters: any, sorter: any) {
        this.pagination = pagination
        this.load()
      },
      onTableRowChanged(selectedRowKeys: any[], selections: any[]) {
        this.selectedRowKeys = selectedRowKeys
        this.selections = selections
      },
      /**
       * 单选模式
       * @param data
       */
      onTableRowEvent(data: any) {
        return {
          onClick: (event: any) => {
            if (!this.multiple) {
              this.$emit('selected', data)
              this.show = false
            }
          }
        }
      },
      /**
       * 多选模式
       */
      save() {
        this.$emit('selected', this.selections)
        this.show = false
      },
      reset() {
        this.selectedRowKeys = []
        this.selections = []
      }
    }
  })
</script>

<style lang="less">

</style>
