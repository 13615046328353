<template>
  <a-input :value="props.name" :size="props.size" placeholder="请选择部门" @click="open" :disabled="disabled">
    <template #suffix>
      <i class="fa fa-sitemap gray" />
    </template>
  </a-input>
  <a-modal title="选择部门" :visible="state.show" :confirm-loading="state.loading" :width="props.width" class="z-input-dept"
           @ok="save" @cancel="state.show = false">
    <a-input-search v-model:value="searchValue" style="margin-bottom: 8px" placeholder="请输入部门名称" />
    <a-tree
        v-model:selectedKeys="tree.selectedKeys"
        v-model:checkedKeys="tree.checkedKeys"
        :tree-data="tree.data"
        :checkable="multiple"
        :expanded-keys="tree.expandedKeys"
        :auto-expand-parent="autoExpandParent"
        @select="nodeSelected"
        @expand="onExpand">
      <template #title="{ title }">
        <span v-if="title.indexOf(searchValue) > -1">
          {{ title.substr(0, title.indexOf(searchValue)) }}
          <span style="color: #f50">{{ searchValue }}</span>
          {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
        </span>
        <span v-else>{{ title }}</span>
      </template>
    </a-tree>
  </a-modal>
</template>

<script lang="ts" setup>
import {reactive, ref, watch} from 'vue'
import {t} from "../config/tools";

const dialogDept = ref()

const $emit = defineEmits(['selected', 'update:id', 'update:name', /* rules自动生成事件 */ 'blur', 'change'])

const props = defineProps({
  id: {
    type: String
  },
  multiple: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'small'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String
  },
  type: {
    type: Boolean
  },
  checkUser: {
    type: Boolean,
    default: true
  },
  useScope: {
    type: Number,
    default: 0
  },
  funCode: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '600px'
  },
})

const state = reactive({
  show: false,
  loading: false,
  searchValue: ''
})
const tree = reactive({
  checkedKeys: [] as string[],
  selectedKeys: [] as string[],
  data: [],
  sourceData: [] as any[],
  dataList: [] as any[],
  selectedNode: null,
  selectedNodes: [] as any[],
  expandedKeys: [] as any[]
})
const autoExpandParent = ref<boolean>(true)
const searchValue = ref<string>('')
watch(searchValue, value => {
  tree.expandedKeys = tree.dataList.map((item: any) => {
    if (item.title.indexOf(value) > -1) {
      return getParentKey(item.key, tree.data)
    }
    return null
  }).filter((item: any, i: any, self: any) => item && self.indexOf(item) === i)
  searchValue.value = value
  autoExpandParent.value = true
})

const getParentKey = (key: any, tree: any): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
}

/**
 * 处理选中事件
 */
const selected = (d: any) => {
  $emit('update:id', d.id)
  $emit('update:name', d.name)
  $emit('selected', d)
}

/**
 * 打开对话框
 */
const open = () => {
  state.loading = true
  const url = props.checkUser ? '/ams/system/query-user-dept-tree' : '/ams/system/query-dept-tree-oa'
  t.get(url, { type: props.type, useScope: props.useScope, funCode: props.funCode }).then((d: any) => {
    tree.sourceData = d.data
    if (tree.sourceData.length > 0) {
      tree.dataList = tree.sourceData.map((item: any) => {
        return {
          key: item.id,
          title: item.name
        }
      })
    }
    // 遍历建立树结构
    const build = (node: any) => {
      let children = d.data.filter((d: any) => d.pid === node.key).map((item: any) => {
        return {
          key: item.id,
          title: item.name
        }
      })
      if (children.length > 0) {
        node.children = children
        children.map((dept: any) => {
          build(dept)
        })
      }
    }

    // 获取根节点
    let root = d.data.filter((e: any) => d.data.filter((ee: any) => e.pid === ee.id).length === 0).map((item: any) => {
      return {
        key: item.id,
        title: item.name
      }
    })
    root.forEach((item: any) => {
      build(item)
    })
    tree.data = root
    // 设置默认值
    if (props.id) {
      if (props.multiple) {
        // 多选模式
        tree.checkedKeys = props.id.split(',')
      } else {
        // 单选模式
        tree.selectedKeys = [props.id]
      }
    }
    state.loading = false
  }).catch((err: any) => {
    state.loading = false
  })
  state.show = true
}
/**
 * 节点选中事件
 */
const nodeSelected = () => {
  // 单选数据
  if (!props.multiple) {
    let data = tree.sourceData.find((d: any) => tree.selectedKeys.indexOf(d.id) > -1)
    $emit('update:id', data.id)
    $emit('update:name', data.name)
    $emit('selected', data)
    state.show = false
  }
}
/**
 * 保存数据
 */
const save = () => {
  let data = tree.sourceData.filter((d: any) => tree.checkedKeys.indexOf(d.id) > -1) as any
  $emit('update:id', data.map((d: any) => d.id).join(','))
  $emit('update:name', data.map((d: any) => d.name).join(','))
  $emit('selected', data)
  state.show = false
}

const onExpand = (keys: any[]) => {
  tree.expandedKeys = keys;
  autoExpandParent.value = false;
};

</script>

<style lang="less">
</style>
