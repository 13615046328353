<template>
  <a-form class="z-search-bar" :model="filterValue" :label-col="{ style: { width: '120px' } }" :wrapper-col="{ span: 12 }"
          :size="size">
    <a-row>
      <a-col :md="span" v-for="(col, i) in cols" :key="i">

        <a-form-item :label="col.title">
          <!-- 文本 || 长文本 -->
          <a-input v-if="col.control === 'text' || col.control === 'longtext'" v-model:value="filterValue[col.dataIndex]"
                   :size="size"/>
          <!-- 整数数字 -->
          <a-input-number v-else-if="col.control === 'number'" v-model:value="filterValue[col.dataIndex]" :size="size"/>
          <!-- 浮点数字 -->
          <a-input-number v-else-if="col.control === 'float'" :step="0.01" v-model:value="filterValue[col.dataIndex]"
                          :size="size"/>
          <!-- 日期（单日） -->
          <a-date-picker v-else-if="col.control === 'date'" v-model:value="filterValue[col.dataIndex]" valueFormat="YYYY-MM-DD" :size="size"/>
          <!-- 日期（单月） -->
          <a-date-picker picker="month" v-else-if="col.control === 'month'" v-model:value="filterValue[col.dataIndex]" format="YYYY-MM" valueFormat="YYYY-MM" :size="size"/>
          <!-- 日期（单月） -->
          <a-date-picker picker="year" v-else-if="col.control === 'year'" v-model:value="filterValue[col.dataIndex]" format="YYYY-MM" valueFormat="YYYY-MM" :size="size"/>
          <!-- 日期（范围） -->
          <a-range-picker v-else-if="col.control === 'date-range'" :size="size" v-model:value="filter[col.dataIndex]" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
          <!-- 日期（范围） -->
          <cc-datetime-range v-else-if="col.control === 'datetime-range'" v-model:value="filterValue[col.dataIndex]" :size="size"/>

          <!-- 数字（范围） -->
          <div class="grow-line" v-else-if="col.control === 'number-range'">
            <span> <a-input-number v-model:value="filterValue[col.dataIndex][0]" :size="size"/></span>
            <span>&nbsp;-&nbsp;</span>
            <span> <a-input-number v-model:value="filterValue[col.dataIndex][1]" :size="size"/></span>
          </div>

          <!-- 下拉选择框，要配合options参数使用，格式：
            options: {
              opts: [{ value: 0, label: '等待' }, { value: 1, label: '正常' }]
            } -->
          <a-select v-else-if="col.control === 'select'"
                    v-model:value="filterValue[col.dataIndex]"
                    :options="col.options.shows || col.options.opts" :size="size">
          </a-select>

          <z-select-dictree v-else-if="col.control === 'dictTree'" v-model:value="filterValue[col.dataIndex]" :last-choose="col.lastChoose" :code="col.code" size="small"/>
          <!-- 业务：部门，格式：
           options: {
            valueField: 'useOrgId'
          } -->
          <z-input-dept v-else-if="col.control === 'dept'"
                        v-model:id="filterValue[col.options.valueField]" v-model:name="filterValue[col.options.nameField]"
                        :check-user-dept="col.options.checkUserDept ? true : false"
                        :options="col.options"
                        :size="size"/>

          <oa-input-dept v-else-if="col.control === 'dept-oa'" v-model:id="filter[col.options.valueField]"
                         v-model:name="filter[col.options.nameField]" :size="size" :use-scope="col.options.useScope ? col.options.useScope : 0" :fun-code="col.options.funCode ? col.options.funCode : ''" />

          <!-- 默认：文本 -->
          <a-input v-else v-model:value="filterValue[col.dataIndex]" :size="size"/>
        </a-form-item>

      </a-col>
      <!-- 自定义内容 -->
      <slot></slot>
      <!-- 功能按钮 -->
      <a-col :md="6" style="padding-left: 120px; white-space: nowrap;">
        <a-button type="primary" :size="size" @click="doSearch">查询</a-button>&nbsp;
        <a-button :size="size" @click="doReset">重置</a-button>
      </a-col>

    </a-row>
  </a-form>
</template>

<script lang="ts">
export default {
  name: 'z-search-bar'
}
</script>

<script lang="ts" setup>
import {computed, onMounted, reactive, ref, watch} from "vue"
import _ from 'lodash'
import ZInputDept from './z-input-dept.vue'
import CcDatetimeRange from "./controls/cc-datetime-range.vue"
import ZSelectDictree from "./z-select-dictree.vue";
import dayjs from 'dayjs'
import OaInputDept from "./oa-input-dept.vue";


const ranges = reactive({
  '今天': [dayjs(), dayjs()],
  '近三天': [dayjs().add(-3, 'day'), dayjs()],
  '近七天': [dayjs().add(-7, 'day'), dayjs()],
  '近一个月': [dayjs().add(-31, 'day'), dayjs()]
})

/** 定义接受参数 */
const props = defineProps({
  columns: {
    type: Object,
    default: {}
  },
  filter: {
    type: Object,
    default: {}
  },
  defaultFilter: {
    type: Object,
    default: {}
  },
  size: {
    type: String
  },
  span: {
    type: Number,
    default: 6
  }
})

/** 定义返回事件 */
const $emit = defineEmits(['search', 'update:filter', 'reset'])

/// region 变量初始化

/** 定义计算属性 */
const cols = computed(() => {
  return props.columns ? props.columns.filter((d: any) => d.query) : []
})

/** 定义变量 */
const filterValue = ref<any>({})

watch(() => props.filter, () => {
  filterValue.value = _.cloneDeep(props.filter)
}, { deep: true })

// watch(() => filterValue.value, () => {
//   $emit('update:filter', _.cloneDeep(filterValue.value))
// }, { deep: true })
/// endregion

/**
 * 执行查询
 */
const doSearch = () => {
  const val = _.cloneDeep(filterValue.value)
  $emit('update:filter', val)
  $emit('search', val)
}
/**
 * 执行重置
 */
const doReset = () => {
  const val = _.cloneDeep(props.defaultFilter)
  filterValue.value = val
  $emit('update:filter', val)
  $emit('reset', val)
  doSearch()
}

onMounted(() => {
  filterValue.value = _.cloneDeep(props.filter)
})
</script>

<style lang="less">

</style>
