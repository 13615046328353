<template>
  <div class="grow-line">
    <span><a-date-picker :show-time="{minuteStep: 5}" v-model:value="date0" format="YYYY-MM-DD HH:mm" valueFormat="YYYY-MM-DDTHH:mm:00" :size="size"/></span>
    <span>&nbsp;-&nbsp;</span>
    <span><a-date-picker :show-time="{minuteStep: 5}" v-model:value="date1" format="YYYY-MM-DD HH:mm" valueFormat="YYYY-MM-DDTHH:mm:00" :size="size"/></span>
  </div>
</template>

<script lang="ts">
export default {
  name: "cc-date-range"
}
</script>

<script lang="ts" setup>
import { computed, ref } from "vue"

const props = defineProps({
  value: {
    type: Object,
    default: [null, null]
  },
  size: {
    type: String
  },
})
const $emit = defineEmits(['update:value'])

// 值
const filterValue = ref<any>([null, null])

// 计算值
const date0 = computed({
  get: () => {
    filterValue.value[0] = props.value[0]
    return filterValue.value[0]
  },
  set: (val: any) => {
    filterValue.value[0] = val
    $emit('update:value', filterValue.value)
  }
})
const date1 = computed({
  get: () => {
    filterValue.value[1] = props.value[1]
    return filterValue.value[1]
  },
  set: (val: any) => {
    filterValue.value[1] = val
    $emit('update:value', filterValue.value)
  }
})
</script>

<style scoped>

</style>